@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,100..700;1,100..700&display=swap');

.login-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('../../Backgrounds/elastoplast.png'); /* Path to the downloaded pattern file */
    font-family: 'Josefin Slab';
    font-weight: 400;
}

.login-my-form {
    width: 35vw;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 25px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    margin-bottom: 10vh;
}

.login-form-group {
    margin-bottom: 20px;
    padding-right: 20px;
}

.login-form-group label {
    display: block;
    margin-bottom: 5px;
}

.login-form-group input,
.login-form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.login-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-button:hover {
    background-color: #0056b3;
}
