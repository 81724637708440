@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,100..700;1,100..700&display=swap');

.home-post-container {
    display: flex;
    flex-direction: row;
    padding-top: 40px;
}

.left-post { background-color: rgba(153, 158, 179, 0.5); }
.right-post { background-color: rgba(175, 139, 161, 0.5); }

.photo-container { width: 40vw; position: relative; }
.right-photo, .right-text { margin-right: 5vw; }
.left-text, .left-photo { margin-left: 10vw; margin-right: 2vw; padding-bottom: 10px; }

.photo-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    max-height: 35vh; /* Adjusted max height for better fitting on smaller screens */
    border-radius: 35px;
    border: 3px dashed #545165;;
    padding: 8px;
}

.photo-container::before {
    content: "";
    display: block;
    padding-top: 75%; /* 4:3 aspect ratio (change as needed) */
}

.text-container {
    width: 50vw; /* Adjusted width for better responsiveness */
    position: relative;
    padding-top: 8px;
}

.home-blog { flex: 1; overflow: hidden; }

.heading { display: flex; flex-direction: row; margin-bottom: 10px; margin-top: 5px;}

.location { align-self: flex-end; font-style: italic; }

.date { margin-left: 5px; opacity: 50%; font-size: 18px;}

.left-post-title, .right-post-title {
    font-size: 25px;
    font-family: "Josefin Slab", serif;
    font-weight: 400;
    text-decoration: underline;
}

.left-post-title { color: #545165; text-decoration-color: #545165; font-weight: bold;}
.right-post-title { color: #655669; text-decoration-color: #655669; font-weight: bold;}

.create-post-container {
    display: flex;
    background-color: rgba(153, 158, 179, 0.5);
    justify-content: flex-end; /* Align items to the right */
    padding-right: 30px;
    padding-top: 10px;
}

.create-post-link {
    display: flex; /* Ensure the link behaves as a flex item */
}

.create-post-icon {
    width: 25px; /* Adjust the size as needed */
    height: 25px; /* Adjust the size as needed */
}

.create-post-icon-container {
    width: 40px; /* Adjust the width as needed */
    height: 40px; /* Adjust the height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(68, 67, 73, 0.5); /* Set the background color to white */
    border-radius: 10px; /* To make it circular, if needed */
}
