@import url('https://fonts.googleapis.com/css2?family=Bad+Script&family=Satisfy&family=WindSong:wght@400;500&display=swap');

.header {
    height: 75vh;
    width: 100vw;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    background-image: url('../../Backgrounds/stardust.png'); /* Path to the downloaded pattern file */
    background-repeat: repeat; /* Repeat the pattern */
    position: relative; /* Set position relative for z-index */
}

.header-title {
    color: #999EB3; /* Text color */
    font-family: "WindSong", cursive;
    font-weight: 500;
    font-size: 8vw; /* Responsive font size */
    text-align: center; /* Center text horizontally */
    position: absolute; /* Set position absolute to place on top of photos */
    top: 35%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center both horizontally and vertically */
    z-index: 2; /* Ensure title text appears above photos */
    width: 100%;
    text-shadow: 
        -1px -1px 0 #545165,  
         1px -1px 0 #545165,
        -1px  1px 0 #545165,
         1px  1px 0 #545165; /* Add black border around the text */
}

.header-photos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80vw; /* Adjust the width to leave space between photos */
    max-width: 90%; /* Ensure the container doesn't exceed 90% of the viewport width */
    z-index: 1; /* Set z-index to ensure photos appear below title text */
}

.header-photos img {
    border-radius: 35px;
    transition: transform 0.3s ease; /* Apply transition on transform property */
    max-width: 30%; /* Limit the maximum width of each photo */
}

/* Apply scale transform on hover */
.header-photos img:hover {
    transform: scale(1.05); /* Increase size by 5% */
}

/* Keep original styling for resolutions 1920x1080 and above */
@media screen and (min-width: 1920px) {
    .header-title {
        font-size: 150px; /* Original font size for larger screens */
    }
}
