.mySwiper {
    width: 80%;
    height: 100%; /* Add height to the swiper container */
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
}

.swiper-slide img {
    max-width: 100%;
    max-height: 40vh;
    border-radius: 35px;
}
