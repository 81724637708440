.post-container {
    display: flex;
    flex-direction: column;
    background-image: url('../../Backgrounds/triangle-mosaic.webp');
    min-height: 100vh;
}

.blog-header {
    display: flex;
    flex-direction: row;
}

.blog-details {
    margin-bottom: 35px;
}

.blog-title {
    font-size: 25px;
    font-family: "Josefin Slab", serif;
    font-weight: 400;
    text-decoration: underline;
}

.blog-location { align-self: flex-end; font-style: italic; }

.blog-date { margin-left: 5px; opacity: 50%; font-size: 18px;}

.blog-text {
    width: 50vw;
    margin: 20px auto;
}

.update-button {
    width: 55px;
    margin-left: auto; /* Align the update button to the right */
}

.home-button {
    position: absolute;
    top: 20px;
    left: 20px;
}

.blog-photo {
    margin: 0 auto;
}

.blog-photo img {
    border-radius: 35px;
    max-height: 40vh;
    max-width: 55vw;
}

.blog-photos-carousel, .blog-photo {
    margin-top: 30px;
}
