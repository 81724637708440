.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('../../Backgrounds/trees.webp'); /* Path to the downloaded pattern file */
}

.my-form {
    width: 55vw;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
    background-color: white;
}

.form-group {
    margin-bottom: 20px;
    padding-right: 20px;
}

label {
    display: block;
    margin-bottom: 5px;
}

input,
textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0056b3;
}

.image-preview {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;
}

.update-image-container {
    position: relative;
}

.update-image-container img {
    max-width: 150px;
    max-height: 150px;
    object-fit: cover;
    margin: 5px;
    border-radius: 5px;
}

.update-image-container button {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    width: 20px;
    height: 20px;
}

.update-image-container button::before {
    content: url('../../Icons/delete.svg');
    width: 100%;
    height: 100%;
    display: block;
}

.update-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.update-home-button {
    margin-right: 10px;
}
